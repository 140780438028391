import { toastRef } from "../../../../context/toastContext/toastContext";

const mainImageChange = (event, size, setMainImage) => {
    if (size === '') {
        toastRef.current('You must choose a size before selecting the main image.');
    }
    else{
        const file = event.target.files[0];
        const imageUrl = URL.createObjectURL(file);
        event.target.value = null;
        setMainImage(imageUrl);
    }
}

export default mainImageChange; 