import { toastRef } from '../../../../context/toastContext/toastContext';

const handleChangeLargerImages = (croppedImages, setLargerImages, setCroppedImages) => {
    if (croppedImages.length !== 0) {
        toastRef.current('Warning: You will have to re-upload all of your images if you choose to change this.', 
            "info", 
            () => {
            setLargerImages((prevValue) => !prevValue);
            setCroppedImages([]);
        });
    }
    else {
        setLargerImages((prevValue) => !prevValue);
    }
}

export default handleChangeLargerImages;