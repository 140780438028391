import { getCroppedImg, resizeImage } from '../../../../utils/modifyImage';


const cropIndividualImage = async (
    resolveCrop, 
    largerImages, 
    constants, 
    selectedImage, 
    cropArea, 
    resetCropperState
) => {
    if (resolveCrop) {
        const gridRatio = largerImages ? constants.LARGE_GRID_RATIO : constants.SMALL_GRID_RATIO;
        const pixels = constants.PPI / gridRatio;
        const croppedImage = await getCroppedImg(selectedImage, cropArea);
        const file = new File([croppedImage], 'image.png', { type: croppedImage.type });
        const resizedImage = await resizeImage(file, pixels, pixels);
        const croppedImageUrl = URL.createObjectURL(resizedImage);
        resolveCrop(croppedImageUrl);
        resetCropperState();
    }
};

export default cropIndividualImage;