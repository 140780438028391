import React, { useState, useRef } from 'react';
import '../../../App.css';
import styles from './collageCreation.module.css';
import { rotateImage } from '../../../utils/modifyImage';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../../layout/navBars/navBar';
import MediumLogoHeader from '../../../layout/mediumLogoHeader/mediumLogoHeader';
import createImage from '../../../services/createImage';
import usePreventScroll from '../../../hooks/preventScroll';
import LoadingDots from '../../../components/loadingDots';
import { useConstants } from '../../../context/constantsContext';
import GeneralButton from '../../../components/generalButton/generalButton';
import useLoadSessionData from './hooks/useLoadSessionData';
import useChangeSize from './hooks/useChangeSize';
import useSetSessionData from './hooks/useSetSessionData';
import uploadSmallImages from './utils/uploadSmallImages';
import cropIndividualImage from './utils/cropIndividualImage';
import mainImageChange from './utils/mainImageChange';
import handleChangeLargerImages from './utils/handleChangeLargerImages';
import HeaderSection from '../../../components/headerSection';
import SizeSelector from '../../../components/sizeSelector/sizeSelector';
import ImageUpload from '../../../components/imageUpload/imageUpload';
import BasicButton from '../../../components/basicButton/basicButton';
import CustomCropper from '../../../components/customCropper/customCropper';
import CropperButton from '../../../components/cropperButton/cropperButton';
import TextInput from '../../../components/textInput/textInput';
import Checkbox from '../../../components/checkbox/checkbox';
import { MARGINS } from '../../../utils/constants/constants';
import { toastRef } from '../../../context/toastContext/toastContext';
import { useLocalDatabase } from '../../../context/databaseContext';
import LoadingScreen from '../../../components/loadingScreen/loadingScreen';


function CollageCreation({ type }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [text, setText] = useState('');
  const [mainImage, setMainImage] = useState(null);
  const [croppedImages, setCroppedImages] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropArea, setCropArea] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [cropperVisible, setCropperVisible] = useState(false);
  const [size, setSize] = useState('');
  const [isCreatingImage, setIsCreatingImage] = useState(false);
  const [largerImages, setLargerImages] = useState(false);
  const [color, setColor] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();
  const { constants } = useConstants();
  const { setVariable, loadVariable, deleteVariable } = useLocalDatabase();
  const cropperRef = useRef({ resolve: null, reject: null });
  let skippedError = new Error('Skipped');
  let cancelError = new Error('Cancel');

  usePreventScroll(cropperVisible);
  useLoadSessionData(type, 
    setText, 
    setCroppedImages, 
    setSize, 
    setColor, 
    setLargerImages, 
    setMainImage,
    loadVariable
  );
  useChangeSize(type, size, mainImage, setMainImage);
  useSetSessionData(type, mainImage, text, croppedImages, size, color, largerImages, setVariable);

  const uploadNewSmallImages = async (event) => {
    uploadSmallImages(event, cropImage, setCroppedImages, setIsCreatingImage, setShowLoading, skippedError, cancelError);
  }

  const cropImage = async (imageUrl) => {
    return new Promise((resolve, reject) => {
      setSelectedImage(imageUrl);
      setCropperVisible(true);
      cropperRef.current = { resolve, reject };
    });
  };

  const skipImage = () => {
    if (cropperRef.current.reject) {
      cropperRef.current.reject(skippedError);
      resetCropperState();
    }
  };

  const resetCropperState = () => {
    setSelectedImage(null);
    setCropperVisible(false);
  };

  const handleMainImageChange = (event) => { // TODO: Add option to make it croppable
    mainImageChange(event, size, setMainImage);
  };

  const confirmCreateImage = async () => {
    toastRef.current("Are you sure you're ready to create the collage?", "info", async () => {
      await createImage(
        size, 
        type, 
        text, 
        mainImage, 
        croppedImages, 
        largerImages, 
        color, 
        navigate, 
        setIsCreatingImage
      );
    });
  };

  const handleCancelCrop = () => {
    setCropperVisible(false);
    setShowLoading(false);
    resetCropperState();
  };

  const confirmCancelCrop = () => {
    toastRef.current("Are you sure you want to cancel cropping all images? You can also skip this image or remove an individual image later", 
      "info", 
      async () => {
        handleCancelCrop();
      });
  }

  const rotate = async () => {
    const newURL = await rotateImage(selectedImage);
    setSelectedImage(newURL);
  }

  const handleDelete = (index) => {
    const updatedImages = [...croppedImages];
    updatedImages.splice(index, 1);
    setCroppedImages(updatedImages);
  };

  const confirmClearImages = () => {
    toastRef.current("Are you sure you want to clear all images?", "info", () => {
      setCroppedImages([]);
      deleteVariable('croppedImages')
    });
  }

  const handleCropIndividualImage = async () => {
    await cropIndividualImage(
      cropperRef.current.resolve, 
      largerImages, 
      constants, 
      selectedImage, 
      cropArea, 
      resetCropperState
    );
  }

  const UploadSmallImagesComponent = () => {
    return (
      <ImageUpload
        id="image-upload"
        title="Add Images"
        onChange={uploadNewSmallImages}
        multiple={true}
        disabled={isCreatingImage}
      />
    )
  }

  return (
    <div>
      {showLoading ?
      <div>
        <LoadingScreen/>
        { cropperVisible &&
        <CustomCropper
          selectedImage={selectedImage}
          crop={crop}
          setCrop={setCrop}
          zoom={zoom}
          setZoom={setZoom}
          setCropArea={setCropArea}
        >
          <div className={styles.wrapRowContainer}>
            <CropperButton
              onClick={handleCropIndividualImage}
              text="Crop"
            />
            <CropperButton
              onClick={rotate}
              text="Rotate Image"
            />
            <CropperButton
              onClick={skipImage}
              text="Skip Image"
            />
            <CropperButton
              onClick={confirmCancelCrop}
              text="Cancel All"
            />
          </div>
        </CustomCropper>
        }
      </div>
      :
      <div>
        <NavBar />
        <div className="App">
          <MediumLogoHeader title={type === 'text' ? 'Text Collage' : 'Image Collage'}/>

          <HeaderSection title='Step 1. Choose an output size in inches - width x height.'>
            <SizeSelector
              size={size}
              setSize={setSize}
              disabled={isCreatingImage}
            />
          </HeaderSection>

          {type === 'text' ?
          <div>
            <HeaderSection 
              title='Step 2. Choose your text to display. (20 character limit)'
              marginTop={MARGINS.LARGE}
            >
              <TextInput
                maxWidth='30%'
                isRequired={false}
                value={text}
                placeholder="Enter Text"
                onChange={(e) => setText(e.target.value)}
                disabled={isCreatingImage}
              />
            </HeaderSection>

            <HeaderSection 
              title='Step 3. Click the box below if you would like your small images to be scaled slightly larger.'
              marginTop={MARGINS.LARGE}
            >
              <HeaderSection
                title='**Warning!!! You will have to re-upload all of your images if you choose to change this, so make your decision on this first. This is due to the way the images are stored on the server once you upload them.**'
                fontSize={14}
              />
              <div className='row-display'>
                <button 
                  style={{ borderRadius: 20, margin: 5, color: 'blue', borderColor: 'blue' }} 
                  onClick={() => toastRef.current('Selecting this will make the small images 0.4x0.4 inches instead of 0.3x0.3 inches.')}
                >
                  ℹ️
                </button>
                <p className={styles.checkboxText}>Larger Images</p>
                <Checkbox
                  id="largerImages"
                  checked={largerImages}
                  onChange={() => handleChangeLargerImages(croppedImages, setLargerImages, setCroppedImages)}
                  disabled={isCreatingImage}
                  marginLeft={5}
                />
              </div>
            </HeaderSection>          

            <HeaderSection 
              title='Step 4. Click here if you would like your images to be in color instead of grayscale.'
              marginTop={MARGINS.LARGE}
            >
              <div className='row-display'>
                <p className={styles.checkboxText}>Color Images</p>
                <Checkbox
                  id="color"
                  onChange={(e) => setColor(e.target.checked)}
                  checked={color}
                  disabled={isCreatingImage}
                  marginLeft={5}
                />
              </div>
            </HeaderSection>

            <HeaderSection 
              title='Step 5. You must choose at least 5 images. They will be cropped to squares for the collage. Please read the warning in Step 3 first.'
              marginTop={MARGINS.LARGE}
            >
              <UploadSmallImagesComponent />
            </HeaderSection>
          </div>
          :
          <div>
            <HeaderSection 
              title='Step 2. The main image is the overall image you would like to print. It will be comprised of the images chosen below.'
              marginTop={MARGINS.LARGE}
            >
              <ImageUpload
                id="main-image-upload"
                title="Choose Main Image"
                onChange={handleMainImageChange}
                disabled={isCreatingImage}
              />
            </HeaderSection>

            {mainImage && (
              <div>
                <img src={mainImage} alt="Collage" className={styles.mainImage} />
              </div>
            )}
            
            <HeaderSection 
              title='Step 3. The smaller images will make up the pixels of the main image. These images are cropped to be squares for use in the collage.'
              marginTop={MARGINS.LARGE}
            >
              <UploadSmallImagesComponent />
            </HeaderSection>
          </div>
          }

          {(croppedImages.length > 0 && !isCreatingImage) && (
            <BasicButton
              onClick={confirmClearImages}
              text="Clear All Images"
            />
          )}

          <div className={styles.wrapRowContainer}>
            {croppedImages.slice().reverse().map((croppedImage, index) => (
              <div key={index} className={styles.imageItem}>
                <img src={croppedImage} alt="Cropped" />
                { !isCreatingImage &&
                <BasicButton
                  onClick={() => handleDelete(index)}
                  text="Delete"
                />
                }
              </div>
            ))}
          </div>

          <HeaderSection
            title={`Step ${type === 'text' ? '6' : '4'}. Let the server run for 15-60 seconds and see the results!`}
            marginTop={MARGINS.LARGE}
          >
            <HeaderSection 
              title="**Time will vary depending on internet connection and number of images uploaded**"
              fontSize={14}
            />
            <GeneralButton
              onClick={confirmCreateImage}
              text={<>{isCreatingImage ? <>Processing<LoadingDots/></> : 'Create Output Image!'}</>}
              disabled={isCreatingImage}
            />
          </HeaderSection>
        </div>
      </div>
      }
    </div>
  );
}

export default CollageCreation;

//TODO: Verify processes on phone
