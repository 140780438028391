import bigLogo from '../../../assets/big-logo.png';
import bothCollages from '../../../assets/bothCollages.png'
import collage1 from '../../../assets/collage1.png'
import collage2 from '../../../assets/collage2.png'
import NavBar from '../../../layout/navBars/navBar';
import styles from './home.module.css';
import { IS_DESKTOP } from '../../../utils/constants/constants';
import HeaderSection from '../../../components/headerSection';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function Home() {
  const [logoClicked, setLogoClicked] = useState(0);
  const navigate = useNavigate();

  const updateClicked = () => {
    if (logoClicked >= 4) {
      navigate('/admin/login');
    }
    setLogoClicked(prev => prev + 1);
  }

  return (
    <div>
      <NavBar/>
      <div className="App">
        <button onClick={updateClicked} className={styles.bigLogo}>
          <img src={bigLogo} alt="Big Logo" />
        </button>
          
        <HeaderSection
          title="Welcome!"
          fontSize={26}
          fontWeight='bold'
        >
          <p style={{ margin: 0 }}>Collage Creations is a small business that provides unique image collages. You can build a collage of images that can look like a larger image or word! Please stop by the "Helpful Tips" page before you begin creating your collage.</p>
        </HeaderSection>

        {IS_DESKTOP ?
        <div style={{ justifyContent: 'center' }}>
          <img 
            src={bothCollages} 
            alt="Collages" 
            className={styles.collageImages}/>
        </div> 
        :
        <div>
          <img 
            src={collage1} 
            alt="Collage"
            className={styles.collageImages}
          />
          <img 
            src={collage2} 
            alt="Collage" 
            className={styles.collageImages}
          />
        </div>
        }
      </div>
    </div>
  );
}

export default Home;
