import { useEffect } from "react";
import { convertImageToBlob } from '../../../../utils/modifyImage';

const useSetSessionData = (type, mainImage, text, croppedImages, size, color, largerImages, setVariable) => {
    useEffect(() => {
        let isMounted = true;

        const saveMainImageToIndexedDB = async (mainImage) => {
            let blob;
            try {
                blob = await convertImageToBlob(mainImage);
                setVariable("mainImage", blob)
            } catch (error) {
                console.error("Couldn't convert image to blob")
            }
        };

        const saveCroppedImagesToIndexedDB = async (images) => {
            const blobs = [];
            try {
                for (const image of images) {
                    const blob = await convertImageToBlob(image);
                    blobs.push(blob);
                }
            } catch (error) {
                console.error("Couldn't convert images to blobs:", error);
                return;
            }
        
            try {
                setVariable("croppedImages", blobs);
            } catch (error) {
                console.error(error);
            }
        };

        const setData = async () => {
            if (croppedImages) {
                if (croppedImages.length !== 0) {
                    saveCroppedImagesToIndexedDB(croppedImages);
                }
            }

            if (size !== '') {
                try {
                    setVariable("size", size);
                } catch (error) {
                    console.error(error);
                }
            }

            if (type === "text") {
                if (!(text === '' && color === false && largerImages === false)) {
                    try {
                        setVariable("text", text);
                        setVariable("color", color);
                        setVariable("largerImages", largerImages);
                    } catch (error) {
                        console.error(error);
                    }
                }
            } else {
                if (mainImage) {
                    await saveMainImageToIndexedDB(mainImage);
                }
            }
        };

        setData();
        return () => {
            isMounted = false;
        };
    }, [type, mainImage, text, croppedImages, size, color, largerImages]);
};

export default useSetSessionData;