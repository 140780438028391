import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchConstants } from '../utils/constants/fetchConstants';
import LoadingScreen from '../components/loadingScreen/loadingScreen';

const ConstantsContext = createContext(null);

export const ConstantsProvider = ({ children }) => {
  const [constants, setConstants] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadConstants = async () => {
      const fetchedConstants = await fetchConstants();
      setConstants(fetchedConstants);
      setLoaded(true);
    };

    loadConstants();
  }, []);

  if (!loaded) {
    return (<LoadingScreen/>)
  }

  return (
    <ConstantsContext.Provider value={{ constants, loaded }}>
      {children}
    </ConstantsContext.Provider>
  );
};

export const useConstants = () => useContext(ConstantsContext);