import { useEffect } from 'react';


const useLoadSessionData = (
    type, 
    setText, 
    setCroppedImages, 
    setSize, 
    setColor, 
    setLargerImages, 
    setMainImage,
    loadVariable
) => {
    useEffect(() => {
        const loadDataFromSession = async () => {
            try {
                const result = await loadVariable("croppedImages");
                const urls = [];
                if (result) {
                    for (const blob of result) {
                        const url = URL.createObjectURL(blob);
                        urls.push(url);
                    }
                    setCroppedImages(urls);
                } else {
                    console.log("Cropped images not found in IndexedDB")
                }
            } catch (error) {
                console.log(`${error}`)
            }

            try {
                const storedSize = await loadVariable("size");
                setSize(storedSize);
            } catch (error) {
                console.log(error);
            }
            
            if (type === 'text') {
                try {
                    const storedText = await loadVariable("text");
                    const storedColor = await loadVariable("color");
                    const storedLargerImages = await loadVariable("largerImages");
                    setText(storedText);
                    setLargerImages(storedLargerImages);
                    setColor(storedColor);
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    const result = await loadVariable("mainImage");
                    if (result) {
                        const url = URL.createObjectURL(result);
                        setMainImage(url);
                    } else {
                        console.log("Main image not found in IndexedDB");
                    }
                } catch (error) {
                    console.error("Failed to load main image:", error);
                }
            }
        };

        loadDataFromSession();
    }, [sessionStorage, type, setText, setCroppedImages, setSize, setColor, setLargerImages, setMainImage]);
};

export default useLoadSessionData;