const validateRequest = (size, type, text, mainImage, croppedImages) => {
    if (!size) {
      return 'Please choose a size before creating the collage.';
    }
    else if (size.length < 1) {
      return 'Please choose a size before creating the collage.';
    }
    else if (type === 'text' && (text.length === 0 || text.length > 20)) {
      return 'Text must be between 1-20 characters';
    }
    else if (type === 'image' && !mainImage) {
      return 'Please select a main image.';
    }
    else if (type === 'text' && croppedImages.length < 5) {
      return 'Please add at 5 small images.';
    }
    else if (croppedImages.length === 0) {
      return 'Please add at least 1 small image.';
    }
    else {
      return '';
    }
}

export default validateRequest;