import { useEffect } from "react";
import { toastRef} from '../../../../context/toastContext/toastContext';

const useChangeSize = (type, size, mainImage, setMainImage) => {
    useEffect(() => {
        if (type === 'text') {
          return
        }
        const handleSizeChange = () => {
          if (size !== '' && mainImage !== null) {
            toastRef.current('You must re-select the main image after changing the size.');
            setMainImage(null);
          }
        };
      
        const sizeDropdown = document.querySelector('.drop-down');
        if (sizeDropdown) {
          sizeDropdown.value = size;
          sizeDropdown.addEventListener('change', handleSizeChange);
        }
      
        return () => {
          if (sizeDropdown) {
            sizeDropdown.removeEventListener('change', handleSizeChange);
          }
        };
      }, [size, mainImage]);
};

export default useChangeSize;