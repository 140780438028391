import imageCompression from 'browser-image-compression';
import { toastRef } from '../../../../context/toastContext/toastContext';

const uploadSmallImages = async (event, cropImage, setCroppedImages, setIsCreatingImage, setShowLoading, skippedError, cancelError) => {
  const files = event.target.files;
  setShowLoading(true);
  setIsCreatingImage(true);

  const newCroppedImages = [];

  for (const file of files) {
    let imageUrl;
    if (file.type === 'image/jpeg') {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 999,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        imageUrl = URL.createObjectURL(compressedFile);
      } catch (error) {
        toastRef.current(`${error.message}`);
      }
    } else if (file.type === 'image/png') {
      imageUrl = URL.createObjectURL(file);
    } else {
      imageUrl = null;
      toastRef.current('One of your files is not png/jpg. It will not be included');
      continue;
    }

    try {
      const croppedImg = await cropImage(imageUrl);
      newCroppedImages.push(croppedImg);
    } catch (error) {
      if (error.message === cancelError.message) {
        setIsCreatingImage(false);
        return;
      } else if (error.message === skippedError.message) {
        continue;
      } else {
        toastRef.current('Could not complete crop');
      }
    } finally {
      setIsCreatingImage(false);
    }
  }

  event.target.value = null;
  setCroppedImages((prevCroppedImages) => [...prevCroppedImages, ...newCroppedImages]);
  setShowLoading(false);
  setIsCreatingImage(false);
};

export default uploadSmallImages;